import { PAGE_CONTEXT as p } from 'Constants'
import base from './base'
import hammerson from './hammerson'
import merge from 'lodash/merge'
import navPanelCategoryData from './navPanelCategoryData/hammersonData'

export default merge(base, hammerson, {
  name: 'us',
  theme: 'v1',
  group: 'hammerson',
  apiKey: 'npgMcLjpDSm7mWgztFGg2K2raSqJWnyPJQQ8sFv663msq4Ny',
  siteVerifyCode: 'er7LwBLu48ofJHncxgDEF6xCUkn88EMJMAH27SuepNs',
  articlesOnProductListPage: true,
  categoryTitle: 'Shop by category',
  hero: {
    title: 'For Living, For Style',
    intro:
      "Discover fashion trends"
  },
  helmet: {
    metaTitleFormat: {
      [p.TREND_LIST]:
        'Get your fashion fix fast with easy-to-shop trends edits. | {{PARTNER_NAME}}',
      [p.ARTICLE_LIST]:
        'All the latest fashion features, beauty inspiration and lifestyle updates. | {{PARTNER_NAME}}'
    },
    partner: 'For Living, For Style',
    articles: {
      title: 'For Living, For Style',
      description: `View all the latest articles to see what's trending`
    },
    defaultDescription: 'For Living, For Style',
  },
  routes: {
    [p.HUB]: {
      page: 'HubPage',
      path: `${process.env.ANT_PREFIX}/hub/:uuid/:slug`
    }
  },
  home: {
    categoryLimit: 9,
    articlesLinkEnabled: false,
    stores: [
      {
        name: 'Dune',
        to:
          'http://www.anrdoezrs.net/links/7187101/type/dlg/sid/homepageijkljf/https://www.dunelondon.com/',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/Dune.png'
      },
      {
        name: 'River island',
        to:
          'https://click.linksynergy.com/deeplink?id=AqjbTxMPHY4&mid=44103&u1=homepageijkljf&murl=https%3A%2F%2Fwww.phase-eight.com%2F',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/river_island.png'
      },
      {
        name: 'New look',
        to:
          'https://www.awin1.com/cread.php?awinaffid=158752&awinmid=1946&clickref=homepageijkljf&platform=dl&p=%5B%5Bhttps%253A%252F%252Fwww.newlook.com%252Fuk%5D%5D',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/new-look.png'
      },
      {
        name: 'JD Sports',
        to:
          'https://www.awin1.com/cread.php?awinmid=1431&awinaffid=158752&clickref=homepageijkljf&p=%5B%5Bhttps%253A%252F%252Fwww.jdsports.co.uk%252F%5D%5D',
        logo:
          'https://ik.imagekit.io/sqhmihmlh/hammerson/brands-we-love/tr:w-200/JD_Sports.png'
      }
    ]
  },
  navPanelCategoryData,
  storesBlacklist: []
})
