import PropTypes from 'prop-types'
import React from 'react'
import { errorRedirect } from 'Utils'
import config from 'Config'

const ErrorView = props => {
  const e = props.error
  if (e) {
    console.log(e)
  }
  const message = props.message.replace('GraphQL error: ', '')
  if (typeof window !== 'undefined' && e.graphQLErrors.length) {
    const redirectLocation = errorRedirect(e).redirectLocation
    if (redirectLocation) {
      window.location.replace(redirectLocation)
    }
  }

  const ErrorUs = () => {
    return (
      <div className='error-section'  style={{backgroundImage: `URL(${props.screenType === 'small' ? 'https://ik.imagekit.io/2fqsvlgz3q/for-living/error-banner-mobile.jpg?updatedAt=1741260382916' : 'https://ik.imagekit.io/2fqsvlgz3q/for-living/error-banner.jpg?updatedAt=1741260382966'})`}}>
        <div className='banner-container'>
          <div className='banner-content-section'>
            <div className='banner-heading'>
              Oops<br />
              Page Not Found
            </div>
            <div className='banner-button-row'>
              <a className='primary-link link-linen' href='/'>
                Back to Homepage
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const ErrorDefault = () => {
    return (
      <div className="app-container">
        <div className="main-container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '400px'
            }}
          >
            <div style={{ textAlign: 'center', lineHeight: '2.5rem' }}>
              <h1 style={{ fontSize: '2.5rem' }}>Something went wrong</h1>
              <h2 style={{ fontSize: '1.5rem' }}>
                {message === 'That product is not found.'
                  ? message
                  : 'Try to refresh the page.'}
              </h2>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return config.name === 'us' ? <ErrorUs /> : <ErrorDefault />
}

ErrorView.propTypes = {
  error: PropTypes.shape({
    graphQLErrors: PropTypes.shape({
      length: PropTypes.any
    })
  }),
  message: PropTypes.shape({
    replace: PropTypes.func
  })
}

export default ErrorView
